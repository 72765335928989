import './Escritorio.css'
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom' //useHistory para navegar entre páginas
import cpf from 'cpf';
import axios from '../../Configuracoes/axiosConfig'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const Escritorio = () => {
    const [selectedOption, setselectedOption] = useState(null);
    const menuOptions = ['Ficha Produtor', 'Planilhas Armazém', 'Adicionar Catação / Bebida / Peneira', 'Liga', 'Relatórios', 'Administrativo'];
    const produtoroptions = ['Adicionar Novo Produtor', 'Adicionar Dados Produtor', 'Lançar Café na Ficha', 'Adiantamento', 'Adicionar Entrada / Saída', 'Saldo Produtor'];
    const tipoConta = ['Conta Corrente', 'Conta Poupança'];
    const history = useHistory(); // Obtém a instância do histórico de navegação
    const [showFichaProdutor, setShowFichaProdutor] = useState(false); //Estado para controlar renderização da Ficha de Produtor
    const [selectedProdutorOption, setSelectedProdutorOption] = useState(false);
    const [selectedTipoConta, setSelectedTipoConta] = useState('');
    const [showCamposProdutor, setShowCamposProdutor] = useState(false);
    const [cadastroSucesso, setCadastroSucesso] = useState(false); //Estado para inserir msg 'Cadastro realizado com sucesso' na tela
    const [erroCadastro, setErroCadastro] = useState(false);
    const [showAlterarProdutor, setShowAlterarProdutor] = useState(false);
    const [showSaldoProdutor, setShowSaldoProdutor] = useState(false);
    const [showLancarFicha, setShowLancarFicha] = useState(false);
    const [info, setInfo] = useState([]); //Estados para controlar os 'produtores' inseridos - inicia com array vazio
    const [showCatacao, setShowCatacao] = useState(false);
    const [primeiroDigito, setPrimeiroDigito] = useState('');
    const [ultimosDigitos, setUltimosDigitos] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    const [produtorData, setProdutorData] = useState({
      nome: '',
      telefone: '',
      cpf: '',
      banco: '',
      agencia: '',
      tipo_conta: '',
      numero_conta: '',
      chave_pix: '',
      nome_correntista: '',
      data: new Date().toISOString().slice(0, 10) // Adicionando a data atual
    });

    const [cafeLote, setCafeLote] = useState({
      lote: '',
      produtor: '',
      peneira: '',
      classificacao: '',
      sacas: '',
      quilos: '',
      catacao: '',
      classificacao: ''
    });

    const tipo_conta = [
     {
      value: 'Conta Corrente',
      label: 'Conta Corrente',
     },
     {
      value: 'Conta Poupança',
      label: 'Conta Poupança',
     },
    ];

      const optionChange = (option) => { // Verifica qual opção foi selecionada no select
        setselectedOption(option);      
        
        if (option === 'Ficha Produtor'){ // Verifica se a opção selecionada é "Ficha Produtor"
          setShowFichaProdutor(true);
          setShowCamposProdutor(false); // Esconde os campos de produtor
          //history.push('/fichaprodutor');
        } else if (option === 'Planilhas Armazém'){ //Verifica se a opção selecionada é "Planilhas Armazém"
          // Se a opção selecionada não for "Ficha Produtor", esconde o segundo select
        setShowFichaProdutor(false);
        setShowCamposProdutor(false); // Esconde os campos de produtor
        history.push('/planilhas'); //Rota desejada
        } else if (option === 'Adicionar Catação / Bebida / Peneira'){
          setShowFichaProdutor(false);
          setShowCamposProdutor(false);
          setShowCatacao(true);
        }
      }  

      const produtorOptionChange = (option) => { //Verifica qual opção foi selecionada para o menu de produtores
        setSelectedProdutorOption(option);
        
        if(option === 'Adicionar Novo Produtor') { //Se foi adicionado no segundo menu a opção 'Adicionar Novo Produtor'
          setShowCamposProdutor(true); // Mostrar os campos de produtor
          setShowAlterarProdutor(false);
          setShowCatacao(false);
        } else if(option === 'Adicionar Dados Produtor') {
          setShowCamposProdutor(false); // Esconder os campos de produtor
          setShowAlterarProdutor(true); // Mostrar os campos de alterar o produtor
          setShowCatacao(false);
          setShowLancarFicha(false);
        } else if(option === 'Lançar Café na Ficha') {
          setShowCamposProdutor(false);
          setShowAlterarProdutor(false);
          setShowLancarFicha(true);
          setShowCatacao(false);
        } else if(option === 'Saldo Produtor') {
          setShowSaldoProdutor(true);
          setShowCamposProdutor(false);
          setShowAlterarProdutor(false);
          setShowLancarFicha(false);
          setShowCatacao(false);
          history.push('/fichaprodutor');
        } else if(option === 'Adicionar Catação') {
          setShowCatacao(true);
          setShowSaldoProdutor(false);
          setShowCamposProdutor(false);
          setShowAlterarProdutor(false);
          setShowLancarFicha(false);
        }
      }

      // const tipoContaOptionChange = (tipoContaSelecionado) => { //Verifica qual opção foi selecionada para o menu de tipo de conta
      //   setSelectedTipoConta(tipoContaSelecionado);
      // }

      // const handleProdutorDataChange = (e) => { //Esta função é um evento de manipulação que é chamado sempre que há uma alteração nos campos de entrada de dados do formulário no componente React.
      //   const { name, value } = e.target;
      //   // Atualiza o estado local dos inputs
      //   setProdutorData({
      //     ...produtorData,
      //     [name]: value
      //   });
      //   //Atualiza o estado principal produtorData
      //   setProdutorData(prevState => ({
      //     ...prevState,
      //     [name]: value
      //   }));
      // }

      const handleAdicionarClick = async () => {
        
        if( produtorData.nome === ''){ // Se o campo de nome estiver vazio
          alert("Preencha os campos Nome e CPF para efetuar o cadastro!");
        } else {
            try{
                // Verifica se o produtor já está cadastrado
                const response = await axios.get(`http://193.203.183.215:8080/produtor/nome/${produtorData.nome}`);
                console.log("response: ", response);

                if (response.data && response.data.length > 0){
                  // Se o produtor já existe, mostra uma mensagem de alerta
                  alert('Esse produtor já está cadastrado!');
                  return;                  
                }
                
                await axios.post('http://193.203.183.215:8080/produtor', produtorData);
                
                //limpa os campos após a inserção
                setProdutorData({
                  nome: '',
                  telefone: '',
                  cpf: '',
                  banco: '',
                  agencia: '',
                  tipo_conta: '',
                  numero_conta: '',
                  chave_pix: '',
                  nome_correntista: ''
                });
                console.log('Produtor cadastrado com sucesso!');
                alert('Produtor cadastrado com sucesso!');
              
            } catch (error){
              if (error.response){
                console.error('Erro na API:', error.response.data.message);
                alert('Produtor já cadastrado!');
              } else {
                console.error('Erro desconhecido!', error);
                alert('Erro ao cadastrar produtor!');
              }              
            }
        }
      }

      useEffect (() => {
        buscarProdutores();
      },[]);

      //Função para buscar produtores
      const buscarProdutores = async () => {
        try{
            const response = await axios.get('http://193.203.183.215:8080/produtor');

            if (response && response.status >= 200 && response.status < 300){
              const produtoresRecebidos = response.data.content; //Acessa a propriedade 'content'

              //Verifica se os dados recebidos são uma array
              if(Array.isArray(produtoresRecebidos)) {
                setInfo(produtoresRecebidos); //Definindo o estado 'info' diretamente com a resposta da API
                console.log(produtoresRecebidos); //Verificando o response da API no console **********************
                return produtoresRecebidos; //Retorna os produtores para serem utilizados posteriormente
              } else {
                console.error('Dados recebidos não são uma array', produtoresRecebidos);
              }
            } else {
              console.error('Erro ao buscar produtores! Código da resposta: ', response ? response.data: 'Indefinido');
            }

        }catch(error){
          console.error('Erro ao buscar os produtores de café!', error);
          return[]; //Retorna um array vazio em caso de erro.
        }
      };

    const formatPhonenumber = (value) => {
      // Remove todos os caracteres que não são números, '(' , ')' e '-'
      return value.replace(/[^\d() -]/g, '');
    }    

    const cleanedValue = (value) => { //Permite a digitação apenas de números, '-' e '.'
       return value.replace(/[^\d.-]/g, '');
    }

    const truncatedValue = (value) => { //Permite a digitação de no máximo 14 dígitos (quantidade do CPF brasileiro)
      return value.slice(0-14);
  }
  
  const handleTipoContaChange = (event) => {
    setSelectedTipoConta(event.target.value);
   }

   const buscarLote = async (primeiroDigito, ultimosDigitos, isBackspace) => {
      // Se o usuário estiver apagando tudo (campo vazio), não realiza a busca e sai da função
      if(isBackspace || !primeiroDigito || primeiroDigito.trim() === ''){
        return; //Sai da função sem fazer nada se o backspace for pressionado ou o campo estiver vazio
      }      
   }

   // Função que será chamada no evento de input para atualizar o estado
   const handleCarregar = () => {
    const valorLote = cafeLote.lote.trim(); //Remove espaços em branco no início e no fim
    
    // Chama a função de busca
    setPrimeiroDigito(valorLote.substring(0, 1).toUpperCase()); //Adquire o primeiro dígito digitado
    setUltimosDigitos(valorLote.slice(-4)); //Adquire os últimos 4 dígitos digitados
    console.log('valorLote: ', valorLote);
    console.log('primeiroDigito: ', valorLote.substring(0, 1));
    console.log('UltimosDigitos: ', valorLote.slice(-4));
    
    //Verifica se o valor do lote começa com 'M' ou 'E'
    if(valorLote.substring(0, 1).toUpperCase() === 'M'){
      console.log('Entrou no if (primeiroDigito === M) da handleCarregar');

      //Realiza a busca de lote para 'M' (Café Máquina)
      axios.get(`http://193.203.183.215:8080/cafemaquina/${valorLote.slice(-4)}`)
      .then((response) => {
        const dados = response.data[0];

        if (dados) {
           setCafeLote((prevState) => ({
             ...prevState,
             produtor: dados.produtor,
             sacas: dados.sacas,
             quilos: dados.quilos
           }));
           console.log(response.data);
       } else {
        alert('Nenhum dado encontrado!');
       }
      })
        .catch((error) => {
          console.error("Erro ao buscar lote de Café Máquina!")
        })

    } else if (valorLote.substring(0, 1).toUpperCase() === 'E'){
      console.log('Entrou no else if (primeiroDigito === E) da handleCarregar');

      //Realiza a busca de lote para 'E' (Café Beneficiado)
      axios.get(`http://193.203.183.215:8080/cafebeneficiado/${valorLote.slice(-4)}`)
      .then((response) => {
        const retorno = response.data[0];

        if (retorno){
          setCafeLote((prevState) => ({
            ...prevState,
            produtor: retorno.produtor,
            sacas: retorno.sacas,
            quilos: retorno.quilos
          }));
         console.log(response.data);
        } else {
          alert("Nenhum dado encontrado!");
        } 
       })
      .catch((error) => {
       console.error("Erro ao buscar lote de Café Beneficiado!", error);
       })
    } else {
      //Exibe o alert de busca incorreta
      alert('Lote não encontrado, tente novamente!');
     }
  }

  const handleChange = (e) => {
    setCafeLote({ ...cafeLote, lote: e.target.value });
   }

  const handleAdicionarCatacao = async () => {

    const valorLote = cafeLote.lote.trim(); //Remove espaços em branco no início e no fim
    const primeiroDigito = valorLote.substring(0, 1).toUpperCase();
    setUltimosDigitos(valorLote.slice(-4));

    //Verifica se há ao menos um dos itens preenchido 
    if(cafeLote.classificacao == '' && cafeLote.catacao == '' && cafeLote.peneira == ''){
      alert('Preencha ao menos um dos itens para cadastrar no lote!');
      return; //Sai da função se os campos não forem preenchidos
    }

    const classificacaoString = cafeLote.classificacao.join(','); //Une as opções selecionadas com vírgulas

    if (primeiroDigito === 'M'){
      try {
          console.log('Entrou no if (primeiroDigito === M)');
          console.log('Lote: ', ultimosDigitos);
          console.log('Dados enviados para PUT:', {
            lote: parseInt(ultimosDigitos, 10),
            catacao: parseInt(cafeLote.catacao, 10),
            peneira: parseInt(cafeLote.peneira, 10),
            classificacao: classificacaoString
          });

          await axios.put('http://193.203.183.215:8080/cafemaquina', {
          lote: parseInt(ultimosDigitos, 10),
          catacao: parseInt(cafeLote.catacao, 10),
          peneira: parseInt(cafeLote.peneira, 10),
          classificacao: classificacaoString // Envia como uma string
        });

        setCafeLote({
          lote: '',
          produtor: '',
          peneira: '',
          catacao: '',
          classificacao: '',
          sacas: '',
          quilos: ''
        })

        alert('Catação, Bebida e Peneira adicionados com sucesso!');
        
      } catch(error){
        console.error('Erro ao adicionar catação / bebida / peneira!', error);
      }
    } else if (primeiroDigito === 'E') {
      try {
          console.log('Entrou no else if (primeiroDigito === E)');
          console.log('Lote: ', ultimosDigitos);
          console.log('Dados enviados para PUT:', {
            lote: parseInt(ultimosDigitos, 10),
            catacao: parseInt(cafeLote.catacao, 10),
            peneira: parseInt(cafeLote.peneira, 10),
            classificacao: classificacaoString
          });

          await axios.put('http://193.203.183.215:8080/cafebeneficiado', {
          lote: parseInt(ultimosDigitos, 10),
          catacao: parseInt(cafeLote.catacao, 10), // Envia null se estiver vazio
          peneira: parseInt(cafeLote.peneira, 10),
          classificacao: classificacaoString // Envia como uma String
       });

       alert('Catação, Bebida e Peneira adicionados com sucesso!');

       setCafeLote({
        lote: '',
        produtor: '',
        peneira: '',
        catacao: '',
        classificacao: '',
        sacas: '',
        quilos: ''
       })
       
      } catch (error){
        console.error('Erro ao adicionar catação / bebida / peneira!');
      }
    } else {
      console.log('Saiu no else');
      alert('Lote inválido!')
    }
  }

   const clickLinhaProdutor = (item) => {
    
    setSelectedItem(item); //rastreia o item (linha) selecionado na tabela
    //Preenche os campos de entrada com os dados da linha clicada
    setProdutorData({
      nome: item.nome,
      telefone: item.telefone,
      cpf: item.cpf,
      banco: item.banco,
      agencia: item.agencia,
      conta: item.conta,
      tipo_conta: item.tipo_conta,
      chave_pix: item.chave_pix,
      nome_correntista: item.nome_correntista
    });
   }

  return (
    <div className='fr'>
      <div className='menu-container'>        
        <select value={selectedOption || ''} // '' se selectedOption for null
          onChange={(e) => optionChange(e.target.value)} //Verifica qual opção foi selecionada no select
          className='dropdown-menu'>
            <option value=''>Selecione uma opção</option> {/* Opção em branco como item padrão */}
              {menuOptions.map((option, index) =>(
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
        </select>
      </div>
      <h1 className='h'> Escritório Minas Café </h1>
      {showFichaProdutor && (
       <div>
        <div className='ar'>
          <h3>Área de Produtor</h3>
        </div>
        <div className='menu-container2'> 
          <select value={selectedProdutorOption || ''}
            onChange={(e) => /*setSelectedProdutorOption*/ produtorOptionChange(e.target.value)}
            className='dropdown-menu'>
             <option value=''>Selecione uma opção</option>
             {produtoroptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {showCamposProdutor && (
          <div className='campos-produtor'>
            <div>
              <h3 className='cad'>Cadastrar Produtor</h3>
            </div>
            <div className='div_nome_tel'>
                <TextField required id="text_nome" className="text_nome" label="Nome:" value={produtorData.nome} variant="outlined"
                onChange={(e) => setProdutorData({ ...produtorData, nome: e.target.value })} style={{ marginBotton:'10px', marginRight: '10px' }}/>
                
                {/* <label className='name_label'>Nome:</label>
                <input type='text' name='text_name' className='text_name' value={produtorData.nome} onChange= {(e) => setProdutorData({ ...produtorData, nome: e.target.value})} /> */}
                
                <TextField required id="tel_label" className='tel_label' label="Telefone:" value={produtorData.telefone} variant="outlined"
                onChange={(e) => setProdutorData({ ...produtorData, telefone: formatPhonenumber(e.target.value) })}/>
            </div>
            <div className='div_cpf_ban_ag'>
                <TextField required id="cpf_text" className='cpf_text' label='CPF:' value={produtorData.cpf} variant="outlined"
                 onChange={(e) => setProdutorData({ ...produtorData, cpf: truncatedValue(e.target.value)})} style={{ marginBotton:'10px', marginRight: '10px' }} />
                {/* <label className='cpf_label'>CPF:</label>
                <input type='text' name='cpf_text' className='cpf_text' value={cleanedValue(truncatedValue(produtorData.cpf))} onChange={(e) => {setProdutorData({ ...produtorData, cpf: e.target.value})}} /> */}
              
                <TextField required id="banco_text" className='banco_text' label='Banco:' value={produtorData.banco} variant="outlined"
                onChange={(e) => setProdutorData({ ...produtorData, banco: e.target.value })} style={{ marginBottom: '10px', marginRight: '10px'}}/>
                {/* <label className='banco_label'>Banco:</label>
                <input type='text' name='banco_text' className='banco_text' value={produtorData.banco} onChange={(e) => setProdutorData({ ...produtorData, banco: e.target.value})} /> */}
              
                <TextField required id="agencia_text" className='agencia_text' label='Agência:' value={produtorData.agencia} variant="outlined"
                 onChange={(e) => setProdutorData({ ...produtorData, agencia: e.target.value })} />
                {/* <label className='ag_label'>Agência:</label>
                <input type='text' name='ag_text' className='ag_text' value={produtorData.agencia} onChange={(e) => setProdutorData({ ...produtorData, agencia: e.target.value})} /> */}

            </div>
            <div className='div_coren'>
               <TextField id="outlined-select-account" select label="Tipo de Conta" value={produtorData.tipo_conta} onChange={(e) => setProdutorData({ ...produtorData, tipo_conta: e.target.value})}
                  className='tipo-conta-select' helperText="Por favor selecione o tipo de conta" variant="outlined">
                  {tipo_conta.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
               </TextField>
             
             {/* <select value={selectedTipoConta || ''} onChange={(e) => tipoContaOptionChange(e.target.value)} className='tipo-conta-menu'>
                <option value=''>Tipo de Conta</option>
                <option value='Conta Corrente'>Conta Corrente</option>
                <option valeu='Conta Poupança'>Conta Poupança</option>
              </select> */}

              <TextField required id="conta" className='num_cont' label="Número da Conta:" dafaultValue="text" variant="outlined" value={produtorData.numero_conta}
               onChange={(e) => setProdutorData({ ...produtorData, numero_conta: e.target.value })} style={{ marginBottom: '10px', marginLeft: '10px', textAlign: 'center'}}/>
              {/* <label className='num_con'>Número da Conta:</label>
              <input type='text' name='num_cont' className='num_cont' value={produtorData.numero_conta.toString()} onChange={(e) => setProdutorData({ ...produtorData, numero_conta: e.target.value})} /> */}
              
              <TextField required id="correntista" className='coren_text' label="Correntista:" defaultValue="text" variant="outlined" value={produtorData.nome_correntista}
               onChange={(e) => setProdutorData({ ...produtorData, nome_correntista: e.target.value })} style={{ marginLeft: '10px'}} />

              {/* <label className='coren_label'>Nome do Correntista:</label>
              <input type='text' name='coren_text' className='coren_text' value={produtorData.nome_correntista} onChange={(e) => setProdutorData({ ...produtorData, nome_correntista: e.target.value})} /> */}
            </div>
            <div className='div_pix'>
                <TextField required id="pix" className='pix_text' label="Chave Pix:" defaultValue="text" variant="outlined" value={produtorData.chave_pix}
                 onChange={(e) => setProdutorData({ ...produtorData, chave_pix: e.target.value})}/>
                {/* <label className='pix_label'>Chave Pix:</label>
                <input type='text' name='pix_text' className='pix_text' value={produtorData.chave_pix} onChange={(e) => setProdutorData({ ...produtorData, chave_pix: e.target.value})} /> */}                
            </div>
            
            <button className='but_e' onClick={handleAdicionarClick}>Adicionar</button>

            < div className='cadastro-mensagem'>
               {cadastroSucesso && <p>Produtor cadastrado com sucesso!</p>}
            </div>  
            <div className='erro-mensagem'>
               {erroCadastro && <p>Erro ao cadastrar produtor</p>}
            </div>
          </div>
        )}
       </div>
      )}
      
      {showAlterarProdutor && (
       <div className='div_ext'>
        <div className='div_inic'>
          <h2>Verifique os dados do produtor a alterar: </h2>
          <TextField required id="nome_text" className='nome_text' label='Nome:' defaultValue="text" variant="outlined" value={produtorData.nome}
            onChange={(e) => setProdutorData({ ...produtorData, nome: e.target.value })} style={{marginRight:'10px'}}/>
          <TextField required id="tel_text" className='tel_text' label='Telefone:' defaultValue="text" variant="outlined" value={produtorData.telefone}
            onChange={(e) => setProdutorData({ ...produtorData, telefone: e.target.value})}style={{marginRight:'10px'}}/>
          <TextField required id="campo_cpf" className='campo_cpf' label='CPF:' defaultValue="text" variant="outlined" value={produtorData.cpf}
            onChange={(e) => setProdutorData({ ...produtorData, cpf: e.target.value})}/>
        </div>
        <div className='div_banco'>
          <TextField required id="ban_text" className='ban_text' label='Banco' defaultValue="text" variant='outlined' value={produtorData.banco}
            onChange={(e) => setProdutorData({ ...produtorData, banco: e.target.value})} style={{marginRight: '10px', marginTop: '5px'}} />
          <TextField required id="ag_text" className='ag_text' label='Agência' defaultValue="text" variant='outlined' value={produtorData.agencia}
            onChange={(e) => setProdutorData({ ...produtorData, agencia: e.target.value})} style={{marginRight: '10px', marginTop: '5px'}} />
          <TextField id="tipo_text" select label="Tipo de Conta:" className='tipo_cont' value={produtorData.tipo_conta} onChange={(e) => setProdutorData({ ...produtorData, tipo_conta: e.target.value})}
            helperText="Por favor, selecione o tipo de conta" variant='outlined' style={{marginTop: '10px'}}>
            {tipo_conta.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                {option.label}
               </MenuItem>
            ))}
          </TextField>
        </div>
        <div className='container-table'>
          <table className='table_ficha'>
            <thead> {/*Cabeçalho*/}
              <tr>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Nome</th>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Telefone</th>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Cpf</th>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Banco</th>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Agência</th>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Número da Conta</th>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Tipo de Conta</th>
                <th style={{backgroundColor: '#DAA520', color: 'black'}}>Chave Pix</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(info) && info.map((item, index) => (
               <tr key={index} onClick={() => clickLinhaProdutor(item)}>
                  <td>{item.nome} </td>
                  <td>{item.telefone}</td>
                  <td>{item.cpf}</td>
                  <td>{item.banco}</td>
                  <td>{item.agencia}</td>
                  <td>{item.numero_conta}</td>
                  <td>{item.tipo_conta}</td>
                  <td>{item.chave_pix}</td>
               </tr>
              ))}
            </tbody>
          </table>
        </div>
       </div>
      )}

      {showCatacao && (
        <div className="cat">
          <div className="tit">
            <h3>Adicionar Catação / Bebida / Peneira</h3>
          </div>
          <div className="forn">
            <h4>Informe o lote para adicionar catação / bebida / peneira:</h4>
            <input
              type="text"
              name="input-lote"
              className="input-lote"
              value={cafeLote.lote}
              placeholder='Exemplo: "M-0125"'
              onChange={handleChange}
            />
            <button className="but_cat" onClick={handleCarregar}>Carregar</button>
          </div>
          <div className="info-prod">
            <input type="text" name="input-prod" className="input-prod" value={cafeLote.produtor} placeholder="Produtor" />
            <input type="text" name="input-sac" className="input-sac" value={cafeLote.sacas} placeholder="Sacas" />
            <input type="text" name="input-quil" className="input-quil" value={cafeLote.quilos} placeholder="Quilos" />
          </div>  	
          <div className="div-cat">
            <h3>Informe a catação, a bebida e a peneira:</h3>
          </div>
          <div className="container-test">
            <div className="teste-1">
              <input
                type="text"
                name="input-cat"
                className="input-cat"
                value={cafeLote.catacao}
                placeholder="Catação"
                onChange={(e) => setCafeLote({ ...cafeLote, catacao: e.target.value })}
              />
              <input
                type="text"
                name="input-pen"
                className="input-pen"
                value={cafeLote.peneira}
                placeholder="Peneira"
                onChange={(e) => setCafeLote({ ...cafeLote, peneira: e.target.value })}
              />
            </div>
            <div className="teste-2">
              <div className="aj-teste-label">
                <label>Bebida:</label>
              </div>
              {['Mole', 'Duro', 'Riado', 'Rio', 'Suja', 'Aproveitamento'].map((opcao) => (
                <div key={opcao} className={`aj-teste-${opcao.toLowerCase()}`}>
                  <input
                    type="checkbox"
                    name="bebida"
                    value={opcao}
                    checked={cafeLote.classificacao?.includes(opcao) || false}
                    onChange={(e) => {
                      const novaClassificacao = e.target.checked
                        ? [...(cafeLote.classificacao || []), opcao]
                        : cafeLote.classificacao?.filter((item) => item !== opcao) || [];
                      setCafeLote({ ...cafeLote, classificacao: novaClassificacao });
                    }}
                  />
                  <label>{opcao}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="div-bot">
            <button className="bot_conf" onClick={handleAdicionarCatacao}>Gravar :)</button>
          </div>
        </div>
      )}

      { showSaldoProdutor

      }
      <div className='footer-container'>
         <Link to="/paginainicial" className='bot'>
            <button className='button'>Voltar</button>
         </Link>
      </div>
    </div>
  );
}

export default Escritorio;