import axios from 'axios';

// Criação da instância do Axios com configurações padrão
const instance = axios.create({
  baseURL: 'http://www.comerciominascafe.com:8080',
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true, //Habilita o envio de cookies e credenciais
});

// Interceptor para adicionar o token às requisições
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // obtém o token do LocalStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;





// axios.interceptors.request.use(
//     config => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             config.headers.Authorization = `Bearer  ${token}`;
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

// export default axios;