import './PlanilhaCoco.css'
import React, { useState, useEffect } from 'react';
import { formatarData } from '../../Utilitarios/utilitario_de_data';
import { dataAmericana } from '../../Utilitarios/formatarDataFormatoAmericano';
import { Link } from 'react-router-dom';
import { consultarLotes } from '../../Funçoes/consultarLote';
import axios from '../../../Configuracoes/axiosConfig';
import Modal from 'react-modal';

const PlanilhaCoco = () => {

  const [sugestoes, setSugestoes] = useState([]); // Estado para lidar com sugestões de nomes de produtores
  const [inputSearch, setInputSearch] = useState(''); // Estado para lidar com a pesquisa do nome filtrado
  const [selectedItem, setSelectedItem] = useState(null); //estado para rastrear o item selecionado na tabela
  const [loteParaEditar, setLoteParaEditar] = useState(null);
  const [confirmarEdicao, setConfirmarEdicao] = useState(false);
  const [lotesConsultados, setLotesConsultados] = useState([]); //'Estado' para armazenar os lotes filtrados com base no produtor consultado
  const [consultarPressionado, setConsultarPressionado] = useState(false); //Variável de valor booleano que verifica se o botão "Consultar" foi pressionado ou não. É inicializada como 'false'.
  const [exibirReferencia, setExibirReferencia] = useState(false); //Estado para controlar a exibição do campo "Referência"com base na opção selecionada no select. É inicializado como 'false'.
  const [dataSelecionada, setDataSelecionada] = useState('');
  const [mostrarTelaConfirmacao, setMostrarTelaConfirmacao] = useState(false);
  const [showModalLancado, setShowModalLancado] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [showModalDesmarcado, setShowModalDesmarcado] = useState(false);

  const [dados, setDados] = useState([]); //Estado para controlar os 'dados'inseridos
  const [novoItem, setNovoItem] = useState({ //Estado para inserir um novo item
    lote: '',
    produtor: '',
    apelido: '',
    meieiro: '',
    data: '',
    sacos: '',
    quilos: '',
    barracao: '',
    status: '',
    referencia: '',
    subproduto: '',
    observacoes: ''
  });

  useEffect(() => {
    if (mostrarTelaConfirmacao) {
      abrirModalConfirmacao(); //Chama a função para abrir o modal *******************************
    }
  }, [mostrarTelaConfirmacao]);

  //Função para buscar lotes de Café em Côco
  const buscarDados = async () => {//assync = função assíncrona, retorna uma 'promisse'.
    try { //O await faz com que a execução do código dentro da função seja pausada até que a promessa retornada por axios.get(...) seja resolvida ou rejeitada.
      const response = await axios.get('http://193.203.183.215:8080/cafecoco');

      if (response && response.status >= 200 && response.status < 300) {
        const dadosRecebidos = response.data;
        setDados(dadosRecebidos); // Definindo o estado 'dados' diretamente com a resposta da API
        console.log(dadosRecebidos); //Verificando o retorno da API no Console ***********************************
        return dadosRecebidos; //Retorna os dados para serem usados posteriormente
      } else {
        console.error('Erro ao buscar um Café em Coco! Código de resposta: ', response ? response.data : 'Indefinido');
        return [];
      }

    } catch (error) {
      console.error('Erro ao buscar um Café em Coco!', error);
      return []; //Retorna um array vazio em caso de erro
    }
  };

  // Função para adicionar um novo item à tabela
  const handleInserirItem = async () => {

    // Verifica se os campos obrigatórios (Produtor, sacos, quilos e data) estão vazios
    if (
      novoItem.produtor === '' ||
      novoItem.sacos === '' ||
      novoItem.quilos === '' ||
      novoItem.data === ''
    ) {
      alert('Preencha os campos produtor, sacos, quilos e data para cadastrar!');
      return;
    }

    if (novoItem.status === "") {
      alert('Selecione um status para cadastrar um novo lote!');
    } else {
      try {
        //Verifica primeiro se o produtor está cadastrado
        const response = await axios.get(`http://193.203.183.215:8080/produtor/nome/${novoItem.produtor}`);

        if (response.data) {

          // Insere o novo item de Café em Côco usando axios.post
          await axios.post('http://193.203.183.215:8080/cafecoco', novoItem);

          //atualizar a lista de dados após a inserção
          buscarDados();

          //limpar campos após a inserção      
          setNovoItem({
            produtor: '',
            apelido: '',
            meieiro: '',
            data: '',
            sacos: '',
            quilos: '',
            barracao: '',
            status: '',
            subproduto: '',
            observacoes: '',
            referencia: '' //limpar também o campo de referência
          });
          setInputSearch('');
        } else {
          //Produtor não encontrado, exibe mensagem de aviso
          alert('O produtor que você está tentando inserir não está cadastrado. Cadastre o produtor antes de inserir um novo lote!');
        }
      } catch (error) {
        console.error('Erro ao inserir item:', error);
      }
    }
  };

  // Função para lidar com a mudança no campo "Produtor"
  const handleProdutorChange = (e) => { // Chama `handleFilter` e `setNovoItem` ao mesmo tempo
    const novoValor = e.target.value;
    setNovoItem({ ...novoItem, produtor: novoValor });
    handleFilter(e); // Chame a função handleFilter aqui para atualizar sugestões
  };

  const getStatusClass = (status) => { //Obtém o status para inserir o css apropriado na linha da tabela
    const statusLowerCase = status.toLowerCase(); //Converte o status para letras minúsculas

    switch (statusLowerCase) {
      case 'já adquirido':
        return 'status-adquirido';
      case 'já beneficiado':
        return 'status-beneficiado';
      case 'vai beneficiar no barracao':
        return 'status-barracao';
      default:
        return '';
    }
  }

  useEffect(() => { //dispara uma função toda vez que uma variável muda. O Hook useEffect é usado para realizar a busca inicial dos dados do banco de dados assim que o componente é montado (componentDidMount). Ele chama a função buscarDados quando o componente é montado.
    buscarDados(); //Buscar dados do backend ao carregar o componente
  }, [/*lotesInseridos*/]); //Atualizar dados quando novos lotes forem inseridos

  useEffect(() => {
    buscarSugestoes();
  }, [inputSearch]);

  const buscarSugestoes = async () => {
    if (inputSearch.length >= 3) {
      try {
        const response = await axios.get(`http://193.203.183.215:8080/produtor/filter?nome=${inputSearch}`);
        setSugestoes(response.data); //Salva o resultado das sugestões retornados após a busca de nomes de produtores filtrados
      } catch (error) {
        console.error('Erro ao buscar sugestões de produtores', error);
        setSugestoes([]);
      }
    } else {
      setSugestoes([]);
    }
  }

  const handleSelecionarSugestao = (sugestao) => { // Função que seleciona o produtor sugerido ao clicar nele
    console.log("Produtor selecionado:", sugestao.nome);
    setInputSearch(sugestao.nome); // Seleciona o nome da sugestão escolhido
    setSugestoes([]); // Limpa as sugestões após selecionar uma sugestão
    setNovoItem({ ...novoItem, produtor: sugestao.nome });
  };

  const handleFilter = (event) => { //atualiza o valor do estado inputSearch com o valor do input que foi alterado. O estado inputSearch
    setInputSearch(event.target.value); //é usado para armazenar o valor do input "Produtor".
  }

  // Função para abrir o modal de confirmação de alteração
  const abrirModalConfirmacao = () => {
    console.log('abrirModalConfirmacao() foi chamada!');

    if (novoItem.produtor === '' ||
      novoItem.sacos === '' ||
      novoItem.quilos === '' ||
      novoItem.data === ''
    ) {
      alert('Preencha os campos produtor, sacos, quilos e data para Editar!');
      console.log('selectedItem não foi selecionado! :(');
    }
    else {
      if (selectedItem) { //Se alguma linha da tabela foi selecionada
        console.log('selectedItem foi selecionado!');
        if (!mostrarTelaConfirmacao) {
          setMostrarTelaConfirmacao(true);
        }
        setLoteParaEditar(selectedItem);
        console.log('O conteúdo de mostrarTelaConfirmacao é: ' + mostrarTelaConfirmacao);
        setConfirmarEdicao(true);
      }
      else { //Se os campos obrigatórios ñ estiverem vazios...
        console.log('selectedItem não foi selecionado! :(');
      }
    }
  }

  //Função de manipulação de click na linha da tabela para carregar dados nos inputs ao clicar na linha
  const clickLinhaTabela = (item) => {
    const dataFormatada = dataAmericana(item.data);

    //atualiza o estado dataSelecionada com a data formatada em formato americano
    setDataSelecionada(dataFormatada);

    setSelectedItem(item); //rastreia o item (linha) selecionado na tabela

    //Preenche os campos de entrada com os dados da linha clicada
    setNovoItem({
      lote: item.lote,
      produtor: item.produtor,
      meieiro: item.meieiro,
      data: dataFormatada, // Define a data no formato "AAAA-MM-DD"
      sacos: item.sacos,
      quilos: item.quilos,
      barracao: item.barracao,
      referencia: item.referencia,
      subproduto: item.subproduto,
      observacoes: item.observacoes,
      status: item.status // Atualiza o estado "status" com o status do lote selecionado
    });

    setInputSearch(item.produtor); //Atualiza o valor do input search
    setLoteParaEditar(item); // Define o item selecionado no estado loteParaEditar
    //abrirModalConfirmacao(item);
  }

  // Função para fechar o modal de confirmação de alteração
  const fecharTelaConfirmacao = () => {
    setLoteParaEditar(null);
    setConfirmarEdicao(false);
    setMostrarTelaConfirmacao(false);
  };

 /*  const consultarLotes = () => {
    const lotesFiltrados = dados.filter(item => item.produtor.toLowerCase().includes(novoItem.produtor.toLowerCase()));

    setLotesConsultados(lotesFiltrados);
    setConsultarPressionado(true);
  } */

  //Função para confirmar a edição
  const confirmarEdicaoLote = async () => {

    //Certificando de que loteParaEditar não é nulo
    if (loteParaEditar) {

      if (novoItem.status === "") {
        alert('Selecione um status antes de editar!');
      } else {
        try {
          // Acessa os valores dos campos do formulário a partir do estado novoItem  
          const { lote, produtor, meieiro, data, sacos, quilos, barracao, status, subproduto, observacoes } = novoItem;
          console.log('novolote: ' + JSON.stringify(novoItem));

          // Formata a data para o formato americano antes de enviar para o backend
          const dataFormatoAmericano = dataAmericana(novoItem.data);

          const requestBody = {
            lote,
            produtor,
            data,
            meieiro,
            sacos,
            quilos,
            barracao,
            status,
            subproduto,
            observacoes
          }

          //Realiza a chamada PUT para atualizar o lote com os dados em loteParaEditar
          await axios.put('http://193.203.183.215:8080/cafecoco', requestBody);

          const dadosAtualizados = await buscarDados();
          setDados(dadosAtualizados);

          //Feche o modal de confirmação
          fecharTelaConfirmacao();

          setNovoItem({
            lote: '',
            produtor: '',
            data: '',
            meieiro: '',
            sacos: '',
            quilos: '',
            barracao: '',
            status: '',
            subproduto: '',
            observacoes: ''
          });
          setInputSearch('');

        } catch (error) {
          console.error('Erro ao editar o lote: ' + loteParaEditar, error);
        }
      }
    } else {
      alert('loteParaEditar na função "confirmarEdicaoLote" qdo clica no botão Editar está vazio');
    }
  }

  const abrirModalLancado = (item) => {
    setShowModalLancado(true);
    setItemSelecionado(item);
  }

  const fecharModalLancado = () => {
    setShowModalLancado(false);
  }

  const abrirModalCancelamento = (item) => {
    setShowModalDesmarcado(true);
    setItemSelecionado(item);
  }

  const fecharModalCancelamento = () => {
    setShowModalDesmarcado(false);
  }

  const handleMarcarModalComoLancado = () => {
    if (selectedItem && selectedItem.lancado !== 'OK') {
      const lote = selectedItem.lote;

      try {
        axios.put('http://193.203.183.215:8080/cafecoco', {
          lote: lote,
          lancado: 'sim'
        })

        buscarDados();
        buscarDados();

        fecharModalLancado();
        alert('Lote marcado como inserido na ficha do produtor!');
      }
      catch (error) {
        console.error('Erro ao marcar lote como inserido:', error);
      }
    } else {
      fecharModalLancado();
    }
  }

  const handleDesmarcarModalComoLancado = () => {
    if (selectedItem && selectedItem.lancado !== '') {
      const bloco = selectedItem.lote;

      try {
            axios.put('http://193.203.183.215:8080/cafecoco', {
            lote: bloco,
            lancado: 'nao'
        })

        fecharModalCancelamento();
        buscarDados();
        buscarDados();
        alert('Lote desmarcado como inserido na ficha do produtor!');
      } catch (error) {
        console.error('Erro ao desmarcar lote:', error);
      }
    } else {
      console.log("Fora do 'if'");
      fecharModalCancelamento();
    }
  }
  return (
    <div className='div_c'>
      <Link to="/escritorio">Voltar</Link> {/* Usando o componente Link para criar um link para a página inicial */}

      <div className='div_l'>
        <h2>Café em Côco</h2>
      </div>
      {/* Inputs para cada atributo */}
      <div className='input-row-c'>
        <div className={`search-bar ${inputSearch.length > 0 ? 'active' : ''}`}> {/*Realiza uma operação de renderização condicional de classes CSS com base no valor da variável inputSearch.*/}
          <input
            value={inputSearch}
            onChange={handleProdutorChange} //{(e) => setNovoItem({ ...novoItem, produtor: e.target.value })} //pega o valor do produto (evento."alvo".valor)
            type="text"
            placeholder="Produtor"
            name='produtor'
            className='prod-c'
          />
          {sugestoes.length > 0 && (
            <ul>
              {sugestoes.map((sugestao) => (
                <li key={sugestao.id} onClick={() => handleSelecionarSugestao(sugestao)}>
                  {sugestao.nome}
                </li>
              ))}
            </ul>
          )}
        </div>
        <input
          type="text"
          placeholder="Meieiro"
          value={novoItem.meieiro}
          onChange={(e) => setNovoItem({ ...novoItem, meieiro: e.target.value })}
          className='mei-c'
        />
        <br></br>
        <input
          type="date"
          placeholder="Data"
          value={novoItem.data}
          onChange={(e) => setNovoItem({ ...novoItem, data: e.target.value })}
          className='dat-c'
        />
        <input
          type="text"
          placeholder="Sacos"
          value={novoItem.sacos}
          onChange={(e) => setNovoItem({ ...novoItem, sacos: e.target.value })}
          className='sac-c'
        />
        <input
          type="text"
          placeholder="Quilos"
          value={novoItem.quilos}
          onChange={(e) => setNovoItem({ ...novoItem, quilos: e.target.value })}
          className='qui-c'
        />
        <input
          type="text"
          placeholder="Barracão"
          value={novoItem.barracao}
          onChange={(e) => setNovoItem({ ...novoItem, barracao: e.target.value })}
          className='bar-c'
        />
        <select
          value={novoItem.status}
          onChange={(e) => setNovoItem({ ...novoItem, status: e.target.value })} //Caso seja clicado, acionará o evento para adicionar o novo item selecionado
          className='st'>
          <option value="">Selecione o status:</option> {/* Opção padrão */}
          <option value="Vai beneficiar no barracao">Vai beneficiar no barracão</option>
          <option value="Já adquirido">Já adquirido</option>
          <option value="Já beneficiado">Já beneficiado</option>
        </select>
        {novoItem.status === "Já beneficiado" && (
          <input
            type="text"
            placeholder="Referência"
            /*value={exibirReferencia}*/
            onChange={(e) => setExibirReferencia(e.target.value)}
            className='ref-c' />
        )} {/* Se o select estiver selecionado como "Já beneficiado" 'e' o botão Inserir for precionado */}

        <input
          type="text"
          placeholder="Mooca, Escolha, Painha..."
          value={novoItem.subproduto}
          onChange={(e) => setNovoItem({ ...novoItem, subproduto: e.target.value })}
          className='sub-c'
        />
      </div>
      <div className='input-row-c'>
        <input
          type="text"
          placeholder="Observações"
          value={novoItem.observacoes}
          onChange={(e) => setNovoItem({ ...novoItem, observacoes: e.target.value })}
          className='obs-c'
        />
      </div>
      <div className='div-c'>
        <button className='but_c' onClick={handleInserirItem}>Inserir</button>
        <button className='but2_c' onClick={abrirModalConfirmacao}>Editar</button>
        <button className='but3_c' onClick={() => consultarLotes(`http://193.203.183.215:8080/cafecoco/filter?produtor=${novoItem.produtor}`, `http://193.203.183.215:8080/cafecoco/filter/meieiro?meieiro=${novoItem.produtor}`, "Café Coco", setLotesConsultados, setConsultarPressionado)}>Consultar</button>
      </div>

      <div className='table-container'>
        {/* Tabela para exibir os registros a partir dos últimos*/}
        <table className='table'>
          <thead> {/*Cabeçalho*/}
            <tr>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Lançado</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Lote</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Data</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Sacos</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Quilos</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Produtor</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Meieiro</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Status</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Referência</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Barracao</th>
              <th style={{ backgroundColor: '#DAA520', color: 'black' }}>Observacoes</th>
            </tr>
          </thead>
          <tbody>
            {consultarPressionado
              ? lotesConsultados.reverse().map((item, index) => (
                <tr key={index} onClick={() => clickLinhaTabela(item)} className={selectedItem === item ? 'selected-row_c' : ''}>
                  <td style={{ backgroundColor: item.lancado === 'sim' ? '#00FF00' : '', fontWeight: 'bold' }} onClick={() => { if (item.lancado !== 'sim') { abrirModalLancado(item) } else { abrirModalCancelamento(item) } }}>{item.lancado === 'nao' ? '' : 'OK'}</td>
                  <td>{'C-0' + item.lote}</td>
                  <td>{formatarData(item.data)}</td>
                  <td style={{ textAlign: 'center' }}>{item.sacos}</td>
                  <td style={{ textAlign: 'center' }}>{item.quilos}</td>
                  <td style={{ textAlign: 'center' }}>{item.produtor}</td>
                  <td>{item.meieiro}</td>
                  <td>{item.status}</td>
                  <td>{item.referencia}</td>
                  <td style={{ textAlign: 'center' }}>{item.barracao}</td>
                  <td>{item.observacoes}</td>
                </tr>
              ))
              : dados.reverse().map((item, index) => (
                <tr key={index} onClick={() => clickLinhaTabela(item)} className={selectedItem === item ? 'selected-row_c' : ''}>
                  <td style={{ backgroundColor: item.lancado === 'sim' ? '#00FF00' : '', fontWeight: 'bold' }} onClick={() => { if (item.lancado !== 'sim') { abrirModalLancado(item) } else { abrirModalCancelamento(item) } }}>{item.lancado === 'nao' ? '' : 'OK'}</td>
                  <td>{'C-0' + item.lote}</td>
                  <td>{formatarData(item.data)}</td>
                  <td style={{ textAlign: 'center' }}>{item.sacos}</td>
                  <td style={{ textAlign: 'center' }}>{item.quilos}</td>
                  <td style={{ textAlign: 'center' }}>{item.produtor}</td>
                  <td>{item.meieiro}</td>
                  <td>{item.status}</td>
                  <td>{item.referencia}</td>
                  <td style={{ textAlign: 'center' }}>{item.barracao}</td>
                  <td>{item.observacoes}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Modal de confirmação */}
      {mostrarTelaConfirmacao && (  // Se confirmarEdicao for 'true' e modalConfirmacao - aí renderiza
        <div className="modal-confirmacao">
          <div className="modal-content-c">
            <p>Tem certeza que deseja alterar os dados desse lote?</p>
            <button onClick={confirmarEdicaoLote}>Sim</button>
            <button onClick={fecharTelaConfirmacao}>Não</button>
          </div>
        </div>
      )}
      <div>
        <Modal isOpen={showModalLancado} className="modal-c" overlayClassName="modal-overlay-c" onRequestClose={fecharModalLancado} contentLabel="Confirmação">
          <div className='modal-content-c'>
            <h2>Deseja marcar este lote como já inserido na ficha do produtor?</h2>
            <button className="modal-button-c" id="simButton-c" onClick={handleMarcarModalComoLancado}>Sim</button>
            <button className="modal-button-c" id="naoButton-c" onClick={fecharModalLancado}>Não</button>
          </div>
        </Modal>
      </div>

      <div>
        <Modal isOpen={showModalDesmarcado} className="modal-d" overlayClassName="modal-overlay-d" onRequestClose={fecharModalCancelamento} contentLabel='Cancelamento'>
          <div className='modal-content-d'>
            <h2>Deseja desmarcar este lote como já inserido na ficha do produtor?</h2>
            <button className='modal-button-d' id="simButton-d" onClick={handleDesmarcarModalComoLancado}>Sim</button>
            <button className='modal-button-d' id="naoButton-d" onClick={fecharModalCancelamento}>Não</button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PlanilhaCoco;