import axios from '../../Configuracoes/axiosConfig';

export const consultarLotes = async(url, url_2, tipo_cafe, setLotesConsultados, setConsultarPressionado) => {

  try{
      //Primeiro realiza a busca por 'produtor'
      //const lotesFiltradosPorProdutor = dados.filter(item => item.produtor.toLowerCase().includes(novoItem.produtor.toLowerCase()));

      console.log(tipo_cafe + " - Antes do axios!");

      const responseProdutor = await axios.get(url);
      const lotesFiltradosProdutor = responseProdutor.data; //Encapsula em um array para manter compatibilidade com a tabela

      console.log("Retorno consulta por Produtor: ", responseProdutor.data);

      if(lotesFiltradosProdutor.length > 0) {
        setLotesConsultados(lotesFiltradosProdutor);
        setConsultarPressionado(true);
        return;
      } else {
        // Caso não encontre produtores, faz a busca pelo meieiro
        const responseMeieiro = await axios.get(url_2);
        const lotesFiltradosMeieiro = responseMeieiro.data;

        console.log("Retorno consulta meieiro: ", responseMeieiro.data);

        if (lotesFiltradosMeieiro.length > 0) { //Se encontrar resultados pelo nome do 'meieiro', exibe os resultados
          setLotesConsultados(lotesFiltradosMeieiro);
          setConsultarPressionado(true);
        } else {
          // Caso não encontre nada, exibe uma mensagem ou faz outra ação
          setLotesConsultados([]);
          alert("Nenhum lote encontrado para esse produtor ou meieiro.");
        }
      }

      setConsultarPressionado(true); // Atualiza o estado para exibir os resultados
  } catch (error) {
    console.error("Erro ao consultar lotes:", error);
    alert("Erro ao consultar lotes.");
  }
}